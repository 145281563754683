<template>
  <div id="dashboard">
    <v-container fluid>
      <v-row align="center" class="mt-n9">
        <v-col class="text-center" cols="12" sm="12">
          <v-card-subtitle class="text-center"
            ><h1>Clientes de éxito</h1></v-card-subtitle
          >
        </v-col>
      </v-row>

      <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
        <v-timeline-item
          v-for="(cliente, i) in clientes"
          :key="i"
          :color="cliente.color"
          :icon="cliente.icon"
          fill-dot
        >
          <template v-slot:opposite>
            <span>{{ cliente.anio }}</span>
          </template>
          <v-card :color="cliente.color" dark justify="center">
            <v-card-title class="title"> {{ cliente.cliente }}</v-card-title>
            <v-card-text class="white text--primary text-center">
              <img :src="cliente.logo" class="mt-2" />
              <img
                v-if="cliente.logo2"
                :src="cliente.logo2"
                class="mt-2 ml-4"
              />
            </v-card-text>
            <v-card-text class="white text--primary text-start">
              <v-btn
                :color="cliente.color"
                class="mx-0"
                outlined
                @click="cliente.action = !cliente.action"
              >
                Más
              </v-btn>
              <v-btn
                :color="cliente.color"
                icon
                @click="cliente.action = !cliente.action"
              >
                <v-icon>{{
                  cliente.action ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-text>
            <v-expand-transition>
              <div v-show="cliente.action">
                <v-divider></v-divider>

                <v-card-text>
                  <span v-if="cliente.text">{{cliente.text}}</span>
                  <ul>
                    <li v-bind:key="i" v-for="(item,i) in cliente.actividades">
                      {{ item.actividad }}
                    </li>
                  </ul>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-timeline-item>
      </v-timeline>
      <v-row align="center" class="mx-1">
        <div class="my-2">
          <v-btn depressed large rounded color="primary white--text" to="/goals"
            >Anterior</v-btn
          >
        </div>
        <v-spacer></v-spacer>
        <div class="my-2">
          <v-btn
            depressed
            large
            rounded
            color="primary white--text"
            to="/products"
            >Siguiente</v-btn
          >
        </div>
      </v-row>
    </v-container>
    <pageFooter></pageFooter>
  </div>
</template>
<script>
import pageFooter from "@/components/Footer.vue";
import header from "@/components/header.vue";
export default {
  components: {
    pageFooter,
  },
  data() {
    return {
      clientes: [
        {
          color: "blue darken-4",
          icon: "mdi-star",
          cliente: "Consubanco",
          logo: "images/clientes/csb.png",
          anio: "2020",
          action: false,
          actividades: [
            { actividad: "Consultoría Tecnológica Financiera" },
            { actividad: "Métodos y procedimientos" },
            { actividad: "Fábrica de software" },
            { actividad: "Staffing" },
          ],
        },
        {
          color: "green darken-2",
          icon: "mdi-star",
          cliente: "Vichhers Carabel México",
          logo: "images/clientes/viss.png",
          anio: "2020",
          action: false,
          actividades: [
            {
              actividad:
                "Desarrollo del sistema de escaneo y control de calidad",
            },
            { actividad: "Venta de equipo de cómputo y periféricos" },
            { actividad: "Soporte técnico" },
          ],
        },
        {
          color: "light-blue darken-3",
          icon: "mdi-star",
          cliente: "Tejidos Emitex",
          logo: "images/clientes/tejidos.png",
          anio: "2020",
          action: false,
          actividades: [
            {
              actividad:
                "Implementación del sistema de gestión de negocios Dharma",
            },
            { actividad: "Servicios Cloud" },
          ],
        },
        {
          color: "red darken-1",
          icon: "mdi-star",
          cliente: "Sodexo",
          logo: "images/clientes/sodexo.png",
          anio: "2019",
          action: false,
          text: "En sociedad con Melius (socio de negocio).",
          actividades: [
            {
              actividad:
                "Desarrollo de los sistemas Sodexo Suite y Preautorizador",
            },
          ],
        },
        {
          color: "light-blue darken-4",
          icon: "mdi-star",
          cliente: "Medici",
          logo: "images/clientes/1.png",
          anio: "2019",
          action: false,
          actividades: [
            {
              actividad:
                "Desarrollo del sistema Walet de prevención de robo de identidad",
            },
            { actividad: "Servicios Cloud" },
          ],
        },
        {
          color: "red accent-4",
          icon: "mdi-star",
          cliente: "Santander",
          logo: "images/clientes/santander.jpg",
          anio: "2019",
          action: false,
          text: "En sociedad con Experis e Infosyst  (socios de negocio).",
          actividades: [{ actividad: "Fábrica de software" }],
        },
        {
          color: "lime darken-1",
          icon: "mdi-star",
          cliente: "Salulleza",
          logo: "images/clientes/salulleza.png",
          logo2: "images/clientes/slim.jpg",
          anio: "2018",
          action: false,
          actividades: [
            { actividad: "Punto de venta" },
            { actividad: "Sistema de gestión de negocios" },
            { actividad: "Servicios Cloud" },
            { actividad: "Soporte técnico" },
            { actividad: "Venta de equipo y periféricos" },
          ],
        },
        {
          color: "light-green darken-2",
          icon: "mdi-star",
          cliente: "Intercam",
          logo: "images/clientes/intercam.png",
          anio: "2018",
          action: false,
          actividades: [
            { actividad: "Consultoría Tecnológica Financiera" },
            { actividad: "Métodos y procedimientos" },
            { actividad: "Fábrica de software" }
          ],
        },
        {
          color: "amber accent-3",
          icon: "mdi-star",
          cliente: "Libertad",
          logo: "images/clientes/libertad.png",
          anio: "2018",
          action: false,
          actividades: [
            { actividad: "Fábrica de software" },
            { actividad: "Staffing" }
          ],
        },
        {
          color: "cyan lighten-3",
          icon: "mdi-star",
          cliente: "Laboratorios Medix",
          logo: "images/clientes/medix.png",
          anio: "2018",
          action: false,
          actividades: [
            { actividad: "BI" },
            { actividad: "Fábrica de software" }
          ],
        },
      ],
    };
  },
  methods: {},
  created() {},
};
</script>
